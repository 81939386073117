import React from 'react';
import Topbar from './components/Topbar';
import Footer from './components/Footer';
import './App.css'; // Global styles

function App() {
  return (
    <div className="App">
      <Topbar />
      <Banner />
      <Footer />
    </div>
  );
}

function Banner() {
  return (
    <div className="banner">
      <h1>Your next obsession</h1>
    
      <p> Currently, we are focused on building a strong team and gathering innovative ideas to bring exciting projects to life.
      </p>
    </div>
  );
}


export default App;
