import React from 'react';
import '../assets/styles/Topbar.css'; 
import logo from '../assets/images/Logo.png'; // Import the image

const Topbar = () => {
  return (
    <div className="topbar">
      <div className="logo">
        <img src={logo} alt="Company Logo" /> {/* Use the imported logo */}
      </div>
      <nav className="nav-links">
        <a href="#about">About</a>
        <a href="#apps">Apps</a>
        <a href="#giftcards">Giftcards</a>
      </nav>
    </div>
  );
};

export default Topbar;
