import React from 'react';
import { FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'; // Import social media icons
import '../assets/styles/Footer.css'; 

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-left">
        <p>Copyright © 2024 Atlasmana.</p>
      </div>
      <div className="footer-right">
        <a href="https://instagram.com/yourcompany" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FaInstagram />
        </a>
        <a href="https://linkedin.com/yourcompany" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <FaLinkedin />
        </a>
        <a href="https://twitter.com/yourcompany" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
          <FaTwitter />
        </a>
      </div>
    </div>
  );
};

export default Footer;
